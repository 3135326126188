<template>

    <div class="airbox">

        <div class="containerchild2">
            <v-touch v-on:swipeleft="onSwipeLeft1" v-on:swiperight="onSwipeRight1" class="scorychild"
                :class="transClass" :swipe-options="{direction: 'horizontal'}">
                <div class="headerboxchildp">
                    <img @click="toair()" src="@/assets/assets/fanhui.png" alt="">
                    <p>上海民防科普教育馆</p>
                </div>
                <p class="headerword" v-if="towimgurl">{{ towimgurl.name }}</p>
                <div v-if="towimgurl" class="img">
                    <img :src="$baseurl + towimgurl.url" style="width:100%; height: 100%;">
                </div>
                <div
                    style="font-family: PingFangSC-Semibold;
                    font-size: 0.36rem;color: #FFFFFF;letter-spacing: 0.04rem;font-weight: 600; 
                    margin-top:0.3rem; "
                    v-if="towimgurl">
                    {{towimgurl.name}}</div>
                <div class="towfont" v-if="newaudio.length != 0" v-html="newaudio[0].content"></div>
                <div class="towfont" v-if="newaudio.length == 0 && towimgurl" v-html="towimgurl.content"></div>
                <div v-if="newaudio.length != 0">
                    <div class="audiobutton">
                        <img style="width:0.28rem ;height: 0.3rem;" src="@/assets/assets/icom_z@2x.png" alt=""
                            @click="next(1)" />
                        <img style="width:0.84rem ;height: 0.84rem;"
                            :src="require(`@/assets/assets/icom_${playStatus ? 't_d' : 'b_d'}@2x.png`)" alt=""
                            @click="StartPlaying()">
                        <img style="width:0.28rem ;height: 0.3rem;" src="@/assets/assets/icom_y@2x.png" alt=""
                            @click="next(3)" />

                    </div>

                </div>

                <div class="audioList" id="aaa" v-if="oneData">
                    <div class="audioContent" id="bbb" v-for=" (item, index) in oneData" :key="item.id">
                        <div class="audioContent1">
                            <div v-if="oneData" style="   font-weight: 500;">{{ item.name }}</div>
                            <img @click="clicktow(item.id, index)"
                                :class="(item.id == towindex && istowshow) ? 'a' : 'b'"
                                :src="require(`@/assets/assets/icom_${(item.id == towindex && istowshow) ? 'kg' : 'kg1'}@2x.png`)"
                                alt="">
                        </div>
                        <div v-for="(items, index) in towData" :class="actives == index ? 'active' : 'towlisttextchild'"
                            v-show="(item.id == towindex && istowshow)" :key="items.id"
                            @click="displayinfo(items.id, index)">{{ items.name }}</div>
                    </div>
                </div>
            </v-touch>
        </div>

    </div>

</template>

<script>
import { towqueryByEId, towthreequeryByEId, inc, towquryByRId } from '@/assets/api/http'
export default {
    data() {
        return {
            oneData: [], //一级数据
            towData: [],  //二级数据
            towindex: null, //保存数据在哪一项
            istowshow: true, //控制展开项的展开和关闭
            towimgurl: null, //  存储文本信息
            actives: null,//控制高亮
            audiourl: null,  // 存储播放音频
            newaudio: [], // 音频信息列表
            newtimer: 0,
            timer: 0,
            towInfoID: null,
            playStatus: false,//播放状态
            audioindex: 0,
            pavdata: [],
            pavindex: 0,
            transClass: ''
        }
    },
    created() {
        this.getTowID()
        towquryByRId({ pavilionId: this.$route.query.pavID })
            .then((res) => {
                // console.log(res);
                if (res.data.code == 200) {
                    this.pavdata = res.data.data
             
                    for (let index = 0; index < this.pavdata.length; index++) {
                        if (this.pavdata[index].id == this.$route.query.id) {
                            this.pavindex = index
                        }
                    }
                }
            })

    },
    mounted() {

    },
    methods: {
        onSwipeLeft1() {
            console.log(1111);
            this.transClass = 'swipe-left';

            if (this.audiourl) {
                // console.log(2);
                this.audiourl.pause();
                this.audiourl = null
                this.playStatus = false
            } else {
                this.audiourl = null
                this.playStatus = false
            }
            this.actives = null

            if (this.pavindex == this.pavdata.length - 1) {
                console.log('到头了');
                this.transClass = ''
            } else {
                setTimeout(() => {
                    this.transClass = ''
                }, 500)
                this.pavindex = this.pavindex + 1
                for (let index = 0; index < this.pavdata.length; index++) {
                    if (index == this.pavindex) {
                        towqueryByEId({ exhibitionId: this.pavdata[index].id, version: 2 })
                            .then(res => {
                                if (res.data.code == 200) {
                                    this.oneData = res.data.data.theme
                                    this.towimgurl = res.data.data.exhibit
                                    this.towimgurl.content = this.towimgurl.content.replace(/<p>/g, '<p style="text-indent:2em;">')
                                    this.newaudio = res.data.data.audioList

                                    for (let index = 0; index < this.newaudio.length; index++) {
                                        this.newaudio[index].content = this.newaudio[index].content.replace(/<p>/g, '<p style="text-indent:2em;">')
                                    }
                                    this.towindex = this.oneData[0].id
                                    towthreequeryByEId({ themeId: this.towindex, version: 2 })
                                        .then(res => {
                                            // console.log(res);
                                            if (res.data.code == 200) {
                                                this.towData = res.data.data
                                            }
                                        })
                                }
                            })

                    }
                }

            }


        },
        onSwipeRight1() {
            this.transClass = 'swipe-right';

            if (this.audiourl) {
                // console.log(2);
                this.audiourl.pause();
                this.audiourl = null
                this.playStatus = false
            } else {
                this.audiourl = null
                this.playStatus = false
            }
            this.audioindex = 0

            if (this.pavindex == 0) {
                console.log('到头了');
                this.transClass = ''
            } else {
                setTimeout(() => {
                    this.transClass = ''
                }, 500)
                this.pavindex = this.pavindex - 1
                for (let index = 0; index < this.pavdata.length; index++) {
                    if (index == this.pavindex) {
                        towqueryByEId({ exhibitionId: this.pavdata[index].id, version: 2 })
                            .then(res => {
                                if (res.data.code == 200) {
                                    this.oneData = res.data.data.theme
                                    this.towimgurl = res.data.data.exhibit
                                    this.towimgurl.content = this.towimgurl.content.replace(/<p>/g, '<p style="text-indent:2em;">')
                                    this.newaudio = res.data.data.audioList

                                    for (let index = 0; index < this.newaudio.length; index++) {
                                        this.newaudio[index].content = this.newaudio[index].content.replace(/<p>/g, '<p style="text-indent:2em;">')

                                    }
                                    this.towindex = this.oneData[0].id
                                    towthreequeryByEId({ themeId: this.towindex, version: 2 })
                                        .then(res => {
                                            // console.log(res);
                                            if (res.data.code == 200) {
                                                this.towData = res.data.data
                                            }
                                        })
                                }
                            })

                    }
                }

            }

        },
        next(id) {
            // console.log(id);
            if (id == 3) {
                if (this.pavindex < this.pavdata.length - 1) {
                    this.onSwipeLeft1()
                } else {
                    console.log('到头了111');
                }
            } else {
                if (this.pavindex !== 0) {
                    console.log(this.pavindex);

                    this.onSwipeRight1()
                } else {
                    console.log('到头了');
                }
            }
        },
        toair() {
            this.$store.state.activeindexchild = null
            this.$store.state.towactivechild = null
            if (this.audiourl) {
                this.audiourl.pause();
            }
            this.$router.push('/childrenPreface')
        },
        getTowID() {
            this.towInfoID = this.$route.query.id
            towqueryByEId({ exhibitionId: this.towInfoID, version: 2 })
                .then(res => {
                    if (res.data.code == 200) {
                        this.oneData = res.data.data.theme
                        this.towimgurl = res.data.data.exhibit
                        this.towimgurl.content = this.towimgurl.content.replace(/<p>/g, '<p style="text-indent:2em;">')
                        this.newaudio = res.data.data.audioList
                        for (let index = 0; index < this.newaudio.length; index++) {
                            this.newaudio[index].content = this.newaudio[index].content.replace(/<p>/g, '<p style="text-indent:2em;">')

                        }
                        // 使用vuex的方法存储当前点击的选项
                        if (this.$store.state.towactivechild) {
                            this.towindex = this.$store.state.towactivechild
                        } else {
                            this.$store.state.towactivechild = this.oneData[0].id
                        }

                        // 用户返回后
                        if (!this.$store.state.routerId) {
                            this.$store.state.routerId = this.$route.query.id
                        } else {
                            if (this.$store.state.routerId == this.$route.query.id) {
                                // this.$store.state.towactivechild = this.oneData[0].id
                            } else {
                                this.$store.state.routerId = this.$route.query.id
                                this.$store.state.activeindexchild = null
                                this.$store.state.towactivechild = this.oneData[0].id
                            }
                        }

                        this.actives = this.$store.state.activeindexchild
                        this.towindex = this.$store.state.towactivechild
                        towthreequeryByEId({ themeId: this.towindex, version: 2 })
                            .then(res => {
                                // console.log(res);
                                if (res.data.code == 200) {
                                    this.towData = res.data.data
                                }
                            })
                    }
                })
        },
        clicktow(id, index) {

            //获取偏移量 
            let offsetTop = document.getElementById('bbb').offsetHeight

            document.getElementById('aaa').scrollTop = index * offsetTop

            this.newtimer = 0
            this.actives = null
            if (this.towindex == id) {
                this.istowshow = !this.istowshow;
            } else {
                this.istowshow = false;
                this.towindex = id;
                this.istowshow = true;
                // console.log(id,this.towindex);
                towthreequeryByEId({ themeId: this.towindex, version: 2 })
                    .then(res => {
                        if (res.data.code == 200) {
                            this.towData = res.data.data
                            // console.log('this.towData', this.towData);
                        }
                    })
            }
        },
        displayinfo(id, index) {
            if (this.audiourl) {
                this.audiourl.pause()
            }
            this.$store.commit("addactive", index)
            this.$store.commit("addactive1", this.towindex)
            let isclick1 = sessionStorage.getItem(`threeisclick${id}`) || ''
            this.actives = index
            let pavid  
            let pavid1
            if(this.pavdata[this.pavindex]){
                pavid = this.pavdata[this.pavindex].id 
                pavid1  = this.$route.query.pavID 
            }else{
                 pavid = undefined
                 pavid1 = undefined
            }

            this.$router.push({
                path: '/airPolicelistchild',
                query: {
                    id: id,
                    chidlcontextindex: this.towindex,
                    pavid: pavid,
                    pavid1: pavid1
                },
            })
            if (isclick1 == id) {
            } else {
                sessionStorage.setItem(`towisclick${id}`, id)
                inc({ type: 3, id: id, version: 2 })
                    .then(res => {
                        // console.log(res);
                    })
            }
        },
        StartPlaying() {
            if (this.audiourl == null) {
                this.audiourl = new Audio()
                if (this.newaudio[0].adultAudio) {
                    this.audiourl.src = this.$baseurl + this.newaudio[0].adultAudio
                } else {
                    this.audioindex = 1
                    this.audiourl.src = this.$baseurl + this.newaudio[1].adultAudio
                }
                this.audiourl.play()

            } else {
                this.audiourl.play()
                // console.log(333);
            }
            this.playStatus = !this.playStatus
            if (this.playStatus == false) {
                this.audiourl.pause();
            }

            let _this = this;
            //监听音频是否布放完毕
            this.audiourl.addEventListener('ended', function () {
                //改变播放状态
                _this.playStatus = false
                console.log('音频播放结束');
            });
        },
        changeAudio(index) {
            //确认为点击的那个以及再次点击相同的按钮时暂停播放
            if (this.audioindex === index) {
                // this.audioindex = 0
                //是否为播放状态
                if (this.audiourl) {
                    // console.log(123);
                    this.audiourl.pause();
                    this.playStatus = false
                } else {
                    this.audiourl.play()
                }
            } else {
                this.audioindex = index
                if (this.audiourl) {
                    this.audiourl.pause();
                    this.audiourl = null
                    this.playStatus = false
                    // console.log(this.startplay);
                }
                let baseurl = this.$baseurl
                let audiourl = this.newaudio[this.audioindex].adultAudio
                // console.log(audiourl);
                //点击changeAudio是创建音频标签
                this.audiourl = new Audio()
                this.audiourl.src = baseurl + audiourl
                let _this = this;
                //监听音频是否布放完毕
                this.audiourl.addEventListener('ended', function () {

                    //    console.log(this.ended);  
                    //改变播放状态

                    _this.playStatus = false
                    console.log('音频播放结束');
                });
            }
        },

    },
    destroyed() {
        if (this.audiourl) {
            this.audiourl.pause();
            this.audiourl = null
        } else {
            console.log('destroyed');
        }
    },



}
</script>

<style lang="scss">
.scorychild {
    touch-action: pan-y !important;
}

.swipe-left {
    transition: all 0.5s;
    transform: translateX(-50%);
}

.swipe-right {
    transition: all 0.5s;
    transform: translateX(50%);
}

.audiobutton {
    display: flex;
    width: 100%;
    height: 1rem;
    margin: 0.3rem 0;
    justify-content: space-around;
    align-items: center;

}

.towfont {
    font-family: PingFangSC-Regular;
    font-size: 0.24rem;
    color: #FFFFFF;
    letter-spacing: 1px;
    text-align: justify;
    line-height: 0.4rem;
    font-weight: 400;
    margin-top: 0.3rem;
}

.playlist {
    margin-bottom: 0.3rem;
    border-bottom: 1px solid #fff;

    //  height: 3rem;
    .listitem {
        display: flex;
        justify-content: space-between;
        font-size: 0.3rem;
        color: rgb(167, 179, 203);
        height: 0.5rem;


    }
}

.airbox {
    width: 100vw;
    min-height: 100vh;
    background-image: url('../../../../../assets/assets/xuesbg.png');


    .containerchild2 {
        width: 90vw;
        min-height: 100vh;
        margin: 0 auto;
        position: relative;
        overflow-y: auto;

        .headerword {
            font-size: 0.5rem;
            display: inline-block;
            color: #FFf;
        }

        .img {
            width: 100%;
            height: 4rem;
            background: #ccc;
            box-shadow: 0 0 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
        }

        .content {
            font-size: 0.3rem;
            margin-top: 0.3rem;
            color: rgb(235, 237, 237);

        }

        .audioList {
            width: calc(100% - 0.6rem);
            min-height: 4rem;
            height: 6rem;
            background: #FFf;
            border-radius: 0.2rem;
            margin: 0.5rem 0;
            // margin-bottom: 2rem;
            padding: 0 0.3rem;
            overflow-y: auto;

            .audioContent {

                position: relative;
                width: 100%;
                // height: 1rem;
                // border-bottom: 1px solid #ccc;
                font-size: 0.3rem;


                .audioContent1 {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid #ccc;
                    padding: 0.3rem 0;

                }

                .towlisttextchild {

                    width: 100%;
                    // height: 1rem;
                    border-bottom: 1px solid #ccc;
                    font-size: 0.3rem;
                    padding: 0.3rem 0;
                    // line-height: 1rem;
                    color: #ccc;
                }

            }


        }
    }

}

.active {
    width: 100%;
    // height: 1rem;
    border-bottom: 1px solid #ccc;
    font-size: 0.3rem;
    padding: 0.3rem 0;
    // line-height: 1rem;

    color: rgb(225, 175, 73);
}

.a {
    width: 0.08rem;
    height: 0.36rem;
    margin: auto 0;
}

.b {
    width: 0.36rem;
    height: 0.08rem;
    margin: auto 0;

}

.headerboxchildp {
    height: 1.3rem;
    width: 100%;
    // background: rgb(31, 46, 53);
    color: #fff;
    font-size: 0.44rem;
    text-align: center;
    line-height: 1.3rem;
    position: relative;

    img {
        position: absolute;
        width: 0.18rem;
        height: 0.4rem;
        top: 50%;
        transform: translateY(-50%);
        left: 0rem;
    }
}
</style>