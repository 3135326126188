<template>
    <div class="cprefacehall2">
        <v-touch v-on:swipeleft="onSwipeLeft1" v-on:swiperight="onSwipeRight1" class="scorychild" :swipe-options="{direction: 'horizontal'}">
            <div class="headerbox">
                <img @click="toHome" src="@/assets/assets/fanhui.png" alt="">
                <p>上海民防科普教育馆</p>
            </div>
            <div class="font3" v-if="civdata">{{ civdata.name }}</div>
            <div class="img"><img style="width:100% ;height:100% ;" v-if="civdata"
                 :src="$baseurl + civdata.url" alt="">
            </div>
            <div class="brief_content ">
                <span class="brief" v-if="civdata">{{  civdata.name }}</span>
                <div class='briefinfo' v-if="civdata" v-html="civdata.content">
                </div>
                <div class="audiobutton">
                    <img style="width:0.28rem ;height: 0.3rem;" src="@/assets/assets/icom_z@2x.png" alt=""
                        @click="next(1)" />
                    <img style="width:0.84rem ;height: 0.84rem;"
                        :src="require(`@/assets/assets/icom_${playStatus ? 't_d' : 'b_d'}@2x.png`)" alt=""
                        @click="StartPlaying()">
                    <img style="width:0.28rem ;height: 0.3rem;" src="@/assets/assets/icom_y@2x.png" alt=""
                        @click="next(3)" />

                </div>
                <div class="playlist2" v-for="( item, index) in audiodata" :key="item.id">
                    <div class="listitem">
                        <div v-if="item.name">{{ item.name }}</div>
                        <div @click="changeAudio(index)">
                            <img style="width:0.42rem ;height: 0.42rem;"
                                :src="require(`@/assets/assets/icom_${playStatus && audioindex === index ? 't_d' : 'b_d'}.png`)"
                                alt="">
                        </div>
                    </div>
                    <!-- <div class="playinfo" v-show="isshow">内容</div> -->
                </div>
                <div style="height:1.1rem">

                </div>
            </div>

            <!-- 展区弹窗 -->
            <div :class="showtext ? ' showtext1' : ' showtext'">
                <div class="info">
                    <div
                        style=" display:flex ; justify-content: space-between;  align-items: center; font-size: 0.5rem; margin-top: 0.3rem; ">
                        <div
                            style="font-family: PingFangSC-Medium;font-size: 0.44rem;color: #FFFFFF;letter-spacing: 0.79px;font-weight: 500;">
                            展区</div>
                        <!-- <div >{{ showtext ? '下拉' : '上拉' }}</div> -->
                        <img style="width:0.36rem; height:0.32rem;" @click="changeshowtext()"
                            :src="require(`@/assets/assets/icom_${showtext ? 'x' : 's'}@2x.png`)" alt="">
                    </div>
                </div>
                <div class="img" v-show="showtext" @click="dispath(item.id, item)" v-for="item in towlistdata "
                    :key="item.id">
                    <img :src="$baseurl + item.url" alt="" style=" width:100%; height:100%;   border-radius: 0.5rem;">
                    <div class="info">
                        <div class="info-left">{{ item.name }}</div>
                        <img class="info-right" style="width:0.6rem; height:0.6rem;" src="@/assets/img/左箭头.png" alt="">
                    </div>
                </div>
            </div>
        </v-touch>
    </div>
</template>

<script>
import { listdata, quryByRId, towquryByRId, inc } from '@/assets/api/http'
import headers from '@/components/header.vue'
import {audioplay} from '../../components/publicJS'
export default {
    components: { headers },
    data() {
        return {
            isshow: false,
            showtext: false,// 控制展区弹窗的显示和隐藏
            pavId: null,
            civdata: null,
            audiodata: [],
            audioindex: 0,
            startplay: null,
            playStatus: false,//播放状态
            towlistdata: null, // 存放二级页面的信息
        }
    },
    methods: {

        onSwipeLeft1: function () {
            this.transClass = 'swipe-left';
            if (this.startplay) {
                // console.log(2);
                this.startplay.pause();
                this.startplay = null
                this.playStatus = false
            } else {
                this.startplay = null
                this.playStatus = false
            }
            this.audioindex = 0
            let ids = this.$route.query.id
            if (ids == 3) {
                this.$router.push({
                    path: '/ReviewProspect',
                    query: { id: 4 }
                })
            }


        },
        onSwipeRight1: function () {
            if (this.startplay) {
                // console.log(2);
                this.startplay.pause();
                this.startplay = null
                this.playStatus = false
            } else {
                this.startplay = null
                this.playStatus = false
            }
            this.audioindex = 0
            let ids = this.$route.query.id
            if (ids == 3) {
                this.$router.push({
                    path: '/CivilAir',
                    query: { id: 2 }
                })
            }


        },

        changeAudio(index) {
          audioplay(index,this)
        },
        // 点击展开关闭展区弹窗
        changeshowtext() {
            this.showtext = !this.showtext
            towquryByRId({pavilionId:this.pavId})
                .then((res) => {
                    // console.log(res);
                    if (res.data.code == 200) {
                        this.towlistdata = res.data.data
                        // console.log(this.towlistdata);
                    }
                })
        },
        dispath(towId) {
            if (this.startplay) {
                this.startplay.pause();
            }
            let isclick1 = sessionStorage.getItem(`towisclick${towId}`) || ''
            // console.log(item);
            // console.log(towId);
            this.$router.push({
                name: 'airPolice',
                query: {
                    id: towId,
                    pavID:this.pavId 
                }
            })
            if (isclick1 == towId) {
            } else {
                sessionStorage.setItem(`towisclick${towId}`, towId)
                inc({
                    type:2, id:towId, version:1})
                    .then(res => {
                        console.log(res);
                    })
            }
        },
        sendID() {
            this.pavId = this.$route.query.id
            //    console.log(typeof(this.pavId) );
            listdata({id:this.pavId})
                .then((res => {
                    if (res.data.code == 200) {

                        this.civdata = res.data.data
                        // console.log(this.civdata);
                        if (this.civdata.content) {
                            this.civdata.content = this.civdata.content.replace(/<p>/g, '<p style="text-indent:2em;">')
                        }
                    }
                }))
        },
        getaudio() {
            // console.log(this.pavId);
            quryByRId({ relationId:this.pavId, 
                version:1})
                .then((res) => {
                    if (res.data.code == 200) {
                        for (let i = 0; i < res.data.data.length; i++) {
                            this.audiodata.push(res.data.data[i])
                        }
                        if (this.audioID) {
                            // console.log(this.filterAudioData);
                            for (let i = 0; i < this.audiodata.length; i++) {
                                if (this.audiodata[i].id == this.audioID) {
                                    this.audioindex = i
                                    this.active = i
                                    this.startplay = new Audio()
                                    this.startplay.src = this.$baseurl + this.audiodata[i].adultAudio
                                }

                            }
                        }
                        // console.log(this.audiodata, 'this.audiodata');

                    }
                })
        },
        StartPlaying() {
            if (this.startplay == null) {
                this.startplay = new Audio()
                this.startplay.src = this.$baseurl + this.audiodata[0].adultAudio
                this.startplay.play()
                let _this = this;
                //监听音频是否布放完毕
                this.startplay.addEventListener('ended', function () {
                    //    console.log(this.ended);  
                    //改变播放状态
                    _this.playStatus = false
                    console.log('音频播放结束');
                });
                console.log(222);
            } else {
                this.startplay.play()
                console.log(333);
            }
            this.playStatus = !this.playStatus
            if (this.playStatus == false) {
                this.startplay.pause();
            }
        },
        toHome() {
            if (this.startplay) {
                this.startplay.pause();
                let r = this.$route
                if (r.path == '/Home') {
                    console.log('已经在首页了');
                } else {
                    this.$router.push('/Home')
                }
            }
            let r = this.$route
            if (r.path == '/Home') {
                console.log('已经在首页了');
            } else {
                this.$router.push('/Home')
            }
        },
        next(id) {
            // console.log(id);
            if (id == 3) {
                if (this.audioindex == this.audiodata.length - 1) {
                    console.log('到头了');
                    this.onSwipeLeft1()
                } else {
                    this.changeAudio(this.audioindex + 1)
                }
            } else {
                if (this.audioindex == 0) {
                    console.log('到头了');
                    this.onSwipeRight1()
                } else {
                    this.changeAudio(this.audioindex - 1)
                }
            }
        },
    },
    created() {
        this.sendID()
        this.getaudio()
    },
    destroyed() {
        if (this.startplay) {
            this.startplay.pause();
            this.startplay = null
        } else {
            console.log('destroyed');
        }

    }
}
</script>

<style lang="scss" >
 .font3 {
        font-size: 0.6rem;
        color: #fff;
        width: 6rem;
        margin: 0 auto;
        height: 1.1rem;
    }
.headerbox {
    height: 1.3rem;
    width: 100%;
    background: rgb(31, 46, 53);
    color: #fff;
    font-size: 0.44rem;
    text-align: center;
    line-height: 1.3rem;
    position: relative;

    img {
        position: absolute;
        width: 0.18rem;
        height: 0.4rem;
        top: 50%;
        transform: translateY(-50%);
        left: 0.5rem;
    }
}
.scorychild {
    touch-action: pan-y !important;
}

.cprefacehall2 {
    // background-image: linear-gradient(227deg, #1F2E35 52%, #FFFFFF 100%);
    width: 100%;
    min-height: 100%;
    background-image: linear-gradient(227deg, #1F2E35 52%, #FFFFFF 100%);
    // overflow-y: auto;
    // overflow: hidden;

    .img {
        font-size: 0.6rem;
        color: #fff;
        width: 100%;
        // margin: 0 auto;
        height: 3.5rem;
        background: #236;
    }




    .brief_content {
        width: 6rem;
        margin: 0 auto;
        // overflow: hidden;

        // height: calc(100% - 3.5rem);

        .brief {
            font-family: PingFangSC-Semibold;
            font-size: 0.36rem;
            color: #FFFFFF;
            letter-spacing: 2px;
            font-weight: 600;


        }

        .briefinfo {
            font-family: PingFangSC-Regular;
            font-size: 0.24rem;
            color: #FFFFFF;
            letter-spacing: 1px;
            text-align: justify;
            line-height: 20px;
            font-weight: 400;
        }

        .audiobutton {
            display: flex;
            width: 100%;
            height: 1rem;
            align-items: center;
            margin: 0.3rem 0;
            justify-content: space-around;

        }

        .playlist2 {
            margin-bottom: 0.3rem;
            overflow: hidden;
            background-color: #30444E;
            height: 1.22rem;
            border-radius: 0.66rem;


            //  height: 3rem;
            .listitem {
                display: flex;
                justify-content: space-between;
                font-size: 0.3rem;
                color: #fff;
                height: 0.6rem;
                border-bottom: 1px solid #fff;
                margin: 0 auto;
                margin-top: 0.3rem;
                width: 85%;

            }

            .playinfo {
                // height: 0.8rem;
                font-size: 0.3rem;
                color: #fff;
                background: #ccc;
            }
        }

    }






    .showtext {
        width: 90vw;
        min-height: 1.1rem;
        background: rgb(42, 61, 68);
        border-radius: 0 0.5rem 0 0;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        box-shadow: 0 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);

        .info {
            width: 6.0rem;
            margin: 0 auto;


        }
    }

    .showtext1 {
        width: 90vw;
        height: 8.2rem;
        background: rgb(42, 61, 68);
        overflow-y: auto;
        border-radius: 0 0.5rem 0 0;
        position: absolute;
        -webkit-overflow-scrolling: touch;
        bottom: 0;
        box-shadow: 0 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);

        .info {
            width: 6.0rem;
            margin: 0 auto;
        }

        .img {
            background: rgb(92, 103, 106);
            position: relative;
            width: 6rem;
            margin: 0.5rem auto;
            height: 3.5rem;
            border-radius: 0.5rem;

            .info {
                position: absolute;
                bottom: 0.3rem;
                display: flex;
                justify-content: space-between;
                width: 100%;
                font-size: 0.5rem;
                color: #fff;

                .info-left {
                    margin-left: 0.4rem;
                }

                .info-right {
                    margin-right: 0.2rem;
                }
            }
        }
    }
}
</style>