<template>
    <v-touch v-on:swipeleft="onSwipeLeft" v-on:swiperight="onSwipeRight" class="scory" :class="transClass"
        :swipe-options="{direction: 'horizontal'}">
        <div class="audiobox2">

            <div style="width:6.3rem; margin: 0 auto;">
                <div class="headerbox3">
                    <img @click="go()" src="@/assets/assets/fanhui.png" alt="">
                    <p>上海民防科普教育馆</p>
                </div>
                <div class="img">
                    <img v-if="audiodata" :src="$baseurl + audiodata.pictureInfo.url" alt="图片丢失了"
                        style="width:100%; height: 100%;">
                </div>
                <p class="headerword" v-if="audiodata">{{ audiodata.audioList[audioindex].name }}</p>
                <div class="content" v-if="audiodata" v-html="audiodata.audioList[audioindex].content"></div>

                <div class="audiobutton">
                    <img style="width:0.28rem ;height: 0.3rem;" src="@/assets/assets/icom_z@2x.png" alt=""
                        @click="next(1)" />
                    <img style="width:0.84rem ;height: 0.84rem;"
                        :src="require(`@/assets/assets/icom_${playStatus ? 't_d' : 'b_d'}@2x.png`)" alt=""
                        @click="StartPlaying()">
                    <img style="width:0.28rem ;height: 0.3rem;" src="@/assets/assets/icom_y@2x.png" alt=""
                        @click="next(3)" />
                </div>
                <div v-if="audiodata">
                    <div class="playlist" v-for=" (item, index) in audiodata.audioList" :key="item.id">
                        <div class="listitem">
                            <div :class="(audioindex == index) ? 'playinfo1' : 'playinfo'">{{ item.name }}</div>
                            <div @click="changeAudio(index)">
                                <img style="width:0.42rem ;height: 0.42rem;"
                                    :src="require(`@/assets/assets/icom_${playStatus && audioindex === index ? 't_d' : 'b_d'}.png`)"
                                    alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-touch>
</template>

<script>
import { towonequryByRpId, towthreequeryByEId } from '@/assets/api/http'
export default {
    data() {
        return {
            audioimg: '',
            audiodata: null,
            startplay: null,
            playStatus: false,
            audioindex: 0,
            filterAudioData: null,
            audioID: this.$route.query.audioId,
            fromrouter: '',
            contentIndex: this.$route.query.contentindex,
            listINdex: 0,
            context: null,
            transClass: ''
        }
    },
    created() {
        this.getaudioInfo()

        towthreequeryByEId({ themeId: this.contentIndex, version: 1 })
            .then(res => {
                this.context = res.data.data
                for (let i = 0; i < this.context.length; i++) {
                    if (this.context[i].id == this.$route.query.id) {
                        this.listINdex = i
                    }
                }


            })
    },
    mounted() {
        console.log();
        this.$parent.aaa = 2
    },
    methods: {
        onSwipeLeft: function () {
            this.audioindex = 0
            this.transClass = 'swipe-left';
            if (this.startplay) {
                // console.log(2);
                this.startplay.pause();
                this.startplay = null
                this.playStatus = false
            } else {
                this.startplay = null
                this.playStatus = false
            }
            if (this.listINdex == this.context.length - 1) {
                console.log('到头了');
                this.transClass = '';

            } else {
                setTimeout(() => {
                    this.transClass = ''
                }, 500)
                this.listINdex = this.listINdex + 1
                this.$store.state.activeindex = this.listINdex
                for (let i = 0; i < this.context.length; i++) {
                    if (i == this.listINdex) {
                        towonequryByRpId({ relationId: this.context[i].id, version: 1 })
                            .then(res => {
                                // console.log(res);
                                if (res.data.code == 200) {
                                    this.audiodata = res.data.data
                                    for (let j = 0; j < this.audiodata.audioList.length; j++) {
                                        this.audiodata.audioList[j].content = this.audiodata.audioList[j].content.replace(/<p>/g, '<p style="text-indent:2em ;">')
                                    }
                                    this.filterAudioData = this.audiodata.audioList
                                    if (this.audioID) {
                                        // console.log(this.filterAudioData);
                                        for (let i = 0; i < this.filterAudioData.length; i++) {
                                            if (this.filterAudioData[i].id == this.audioID) {
                                                this.audioindex = i
                                                this.startplay = new Audio()
                                                this.startplay.src = this.$baseurl + this.filterAudioData[i].adultAudio
                                            }
                                        }
                                    }
                                }
                            })
                    }
                }
            }
        },
        onSwipeRight: function () {
            this.transClass = 'swipe-right';
            this.audioindex = 0
            if (this.startplay) {
                // console.log(2);
                this.startplay.pause();
                this.startplay = null
                this.playStatus = false
            } else {
                this.startplay = null
                this.playStatus = false
            }
            if (this.listINdex == 0) {
                console.log('到头了');
                this.transClass = '';

            } else {
                setTimeout(() => {
                    this.transClass = ''
                }, 500)
                this.listINdex = this.listINdex - 1
                this.$store.state.activeindex = this.listINdex
                for (let i = 0; i < this.context.length; i++) {
                    if (i == this.listINdex) {
                        towonequryByRpId({ relationId: this.context[i].id, version: 1 })
                            .then(res => {
                                // console.log(res);
                                if (res.data.code == 200) {
                                    this.audiodata = res.data.data
                                    // console.log(this.audiodata);
                                    for (let j = 0; j < this.audiodata.audioList.length; j++) {
                                        this.audiodata.audioList[j].content = this.audiodata.audioList[j].content.replace(/<p>/g, '<p style="text-indent:2em ;">')
                                    }
                                    this.filterAudioData = this.audiodata.audioList

                                    if (this.audioID) {
                                        // console.log(this.filterAudioData);
                                        for (let i = 0; i < this.filterAudioData.length; i++) {
                                            if (this.filterAudioData[i].id == this.audioID) {
                                                this.audioindex = i
                                                this.startplay = new Audio()
                                                this.startplay.src = this.$baseurl + this.filterAudioData[i].adultAudio
                                            }
                                        }
                                    }
                                }
                            })

                    }
                }
            }

        },

        go() {
            let fullpath = `${this.fromrouter.path}?id=${this.$route.query.pavid}&pavID=${this.$route.query.pavid1}` || '/'
            let fullpath1 = this.fromrouter.fullPath
            let fullpath2 = this.fromrouter.path
            console.log(fullpath);
            if (fullpath == '/undefined?id=undefined&pavID=undefined' || fullpath1 == '/') {
                if (this.startplay) {
                    this.startplay.pause();
                    this.$router.push('/Home')

                } else {

                    this.$router.push('/Home')
                }
            } else {
                if (fullpath2 !== '/airPolice') {
                    console.log(1);
                    if (this.startplay) {
                        this.startplay.pause();
                        this.$router.push(fullpath1)
                        console.log(1222);
                    } else {
                        this.$router.push(fullpath1)
                    }

                } else {
                    console.log(2);
                    if (fullpath == '/airPolice?id=undefined&pavID=undefined') {
                        if (this.startplay) {
                            this.startplay.pause();
                            this.$router.push(fullpath1)

                        } else {
                            console.log(this.fromrouter);
                            this.$router.push(fullpath1)
                        }
                    } else {
                        if (this.startplay) {
                            this.startplay.pause();
                            this.$router.push(fullpath)
                            console.log(1222);
                        } else {
                            this.$router.push(fullpath)
                        }
                    }

                }

            }

        },
        getaudioInfo() {
            towonequryByRpId({ relationId: this.$route.query.id, version: 1 })
                .then(res => {
                    // console.log(res);
                    if (res.data.code == 200) {
                        this.audiodata = res.data.data
                        // console.log(this.audiodata);
                        for (let j = 0; j < this.audiodata.audioList.length; j++) {
                            this.audiodata.audioList[j].content = this.audiodata.audioList[j].content.replace(/<p>/g, '<p style="text-indent:2em ;">')
                        }
                        this.filterAudioData = this.audiodata.audioList
                        if (this.audioID) {
                            // console.log(this.filterAudioData);
                            for (let i = 0; i < this.filterAudioData.length; i++) {
                                if (this.filterAudioData[i].id == this.audioID) {
                                    this.audioindex = i
                                    this.startplay = new Audio()
                                    this.startplay.src = this.$baseurl + this.filterAudioData[i].adultAudio
                                }
                            }
                        }
                    }
                })
        },
        StartPlaying() {
            // console.log(this.startplay);
            if (this.startplay == null) {
                console.log(2);
                this.startplay = new Audio()
                this.startplay.src = this.$baseurl + this.audiodata.audioList[0].adultAudio
                this.startplay.play()
                let _this = this;
                //监听音频是否布放完毕
                this.startplay.addEventListener('ended', function () {
                    //改变播放状态
                    _this.playStatus = false
                    console.log('音频播放结束');
                });
            } else {
                this.startplay.play()
            }
            this.playStatus = !this.playStatus
            if (!this.playStatus) {
                this.startplay.pause();
            }
        },
        next(id) {
            // console.log(id);
            if (id == 3) {
                if (this.audioindex == this.audiodata.audioList.length - 1) {
                    console.log('到头了');
                    this.onSwipeLeft()
                } else {
                    this.changeAudio(this.audioindex + 1)
                }
            } else {
                if (this.audioindex == 0) {
                    console.log('到头了');
                    this.onSwipeRight()
                } else {
                    this.changeAudio(this.audioindex - 1)
                }
            }
        },
        changeAudio(index) {
            //确认为点击的那个以及再次点击相同的按钮时暂停播放
            if (this.audioindex === index) {
                // this.audioindex = 0
                //是否为播放状态
                if (this.startplay) {
                    // console.log(123);
                    this.startplay.pause();
                    this.playStatus = false
                }
            } else {
                this.currentAudio = null
                this.audioindex = index
                //获取当前点击的音频地址
                if (this.startplay) {
                    this.startplay.pause();
                    this.startplay = null
                    this.playStatus = false
                }

                let baseurl = this.$baseurl

                let audiourl = this.filterAudioData[this.audioindex].adultAudio
                // console.log(audiourl);
                //点击changeAudio是创建音频标签
                this.startplay = new Audio()
                this.startplay.src = baseurl + audiourl
                let _this = this;
                //监听音频是否布放完毕
                this.startplay.addEventListener('ended', function () {
                    //    console.log(this.ended);  
                    //改变播放状态
                    _this.playStatus = false
                    console.log('音频播放结束');
                });
            }
        },

    },
    destroyed() {
        if (this.startplay) {
            // console.log(2);
            this.startplay.pause();
            this.startplay = null
        } else {
            console.log('destroyed');
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log(from);
        next(vm => {
            vm.fromrouter = from
        })
    }
}
</script>

<style lang="scss">
.scory {
    touch-action: pan-y !important;
}

.swipe-left {
    transition: all 0.5s;
    transform: translateX(-50%);
}

.swipe-right {
    transition: all 0.5s;
    transform: translateX(50%);
}

.audiobox2 {
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    background: linear-gradient(rgb(31, 46, 53), rgb(92, 103, 106));
}

.img {
    width: 100%;
    height: 4rem;
    background: #ccc;
    box-shadow: 0 0 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
}

.headerword {
    font-family: PingFangSC-Semibold;
    font-size: 0.36rem;
    color: #FFFFFF;
    letter-spacing: 2px;
    margin-top: 0.3rem;
    font-weight: 600;
}

.content {

    margin-top: 0.3rem;
    font-family: PingFangSC-Regular;
    font-size: 0.24rem;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: justify;
    line-height: 20px;
    font-weight: 400;

}

.audiobutton {
    display: flex;
    width: 100%;
    height: 1rem;
    margin: 0.3rem 0;
    justify-content: space-around;
    align-items: center;

}

.playlist {
    padding: 0.1rem 0 0.2rem 0;
    border-bottom: 1px solid #fff;
    margin-bottom: 0.3rem;

    .listitem {
        display: flex;
        justify-content: space-between;
        font-size: 0.3rem;
        color: rgb(167, 179, 203);
        height: 0.5rem;


    }

    .playinfo {
        // height: 0.8rem;
        font-size: 0.3rem;
        color: #ddd;
        // background: #ccc;
    }

    .playinfo1 {
        // height: 0.8rem;
        font-size: 0.3rem;
        color: rgb(254, 170, 4);
        // background: #ccc;
    }

}

.headerbox3 {
    height: 1.3rem;
    width: 100%;
    background: rgb(31, 46, 53);
    color: #fff;
    font-size: 0.44rem;
    text-align: center;
    line-height: 1.3rem;
    position: relative;

    img {
        position: absolute;
        width: 0.18rem;
        height: 0.4rem;
        top: 50%;
        transform: translateY(-50%);
        left: 0rem;
    }
}
</style>