<template>
    <div class="cprefacehallchild4">
        <v-touch v-on:swipeleft="onSwipeLeft1" v-on:swiperight="onSwipeRight1" class="scorychild" :swipe-options="{direction: 'horizontal'}">
            <div class="headerbox4">
                <img @click="toHome" src="@/assets/assets/fanhui.png" alt="">
                <p>上海民防科普教育馆</p>
            </div>
            <div class="font" v-if="prefaceData">{{ prefaceData.name }}</div>
            <div class="content">
                <div class="img" v-if="prefaceData"> 
                    <img style="width:100% ;height:100% ;border-radius: 0.3rem;"
                        :src="audioindex == 1? $baseurl + '/profile/upload/风采墙.jpg' : $baseurl + prefaceData.url" alt=""></div>
                <div class="brief_content ">
                    <div class="brief4" v-if="filterAudioData[audioindex]">{{filterAudioData[audioindex].name}}</div>

                    <div class='briefinfo' v-if="filterAudioData[audioindex]"
                        v-html="filterAudioData[audioindex].content">
                    </div>

                    <div class="audiobutton">
                        <img style="width:0.28rem ;height: 0.3rem;" src="@/assets/assets/icom_z@2x.png" alt=""
                            @click="next(1)" />
                        <img style="width:0.84rem ;height: 0.84rem;"
                            :src="require(`@/assets/assets/icom_${playStatus ? 't_d' : 'b_d'}@2x.png`)" alt=""
                            @click="StartPlaying()">
                        <img style="width:0.28rem ;height: 0.3rem;" src="@/assets/assets/icom_y@2x.png" alt=""
                            @click="next(3)" />

                    </div>

                    <div>
                        <div class="playlist" v-for=" (item, index) in filterAudioData " :key="item.id">
                            <div class="listitem">
                                <div :class="active == index ? 'activechild4' : ''">{{ item.name }}</div>
                                <div @click="changeAudio(index)">
                                    <img style="width:0.42rem ;height: 0.42rem;"
                                        :src="require(`@/assets/assets/icom_${playStatus && audioindex === index ? 't_d' : 'b_d'}.png`)"
                                        alt="">
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </v-touch>
    </div>
</template>

<script>

import headers from '@/components/header.vue'
import { listdata, quryByRId } from '@/assets/api/http'
export default {

    components: { headers },
    data() {
        return {
            pavId: this.$route.query.id,
            prefaceData: null, //存储主体内容信息
            audiodata: null, //存储音频内容信息
            filterAudioData: [], //存储过滤音频内容信息
            audioindex: 0,
            startplay: null, //存储音频播放的
            playStatus: false,//播放状态
            filterAudioContent: null,
            active: 0,
            audioID: this.$route.query.audioId
        }
    },
    created() {
        //获取页面进来的ID
        this.getID()
        //获取音频信息
        this.getaudio()
    },
    methods: {

        onSwipeLeft1() {
      console.log('到头了');
        },
        onSwipeRight1() {
            if (this.startplay) {
                // console.log(2);
                this.startplay.pause();
                this.startplay = null
                this.playStatus = false
            } else {
                this.startplay = null
                this.playStatus = false
            }
            this.audioindex = 0
            let ids = this.$route.query.id
            if (ids == 4) {
                this.$router.push({
                    path: '/DisasterPreventionchild',
                    query: { id: 3 }
                })
            }
        },


        // 改变点击音频播放按钮的状态和上面显示的信息内容和音频播放内容
        changeAudio(index) {
            this.active = index
            //确认为点击的那个以及再次点击相同的按钮时暂停播放
            if (this.audioindex === index) {
                // this.audioindex = 0

                //是否为播放状态
                if (this.startplay && this.playStatus == true) {

                    this.startplay.pause();
                    this.playStatus = false

                } else {
                    if (this.startplay) {
                        this.startplay.play();
                        this.playStatus = true
                    } else {
                        let baseurl = this.$baseurl
                        let audiourl = this.filterAudioData[0].adultAudio
                        // console.log(audiourl);
                        //点击changeAudio是创建音频标签
                        this.startplay = new Audio()
                        this.playStatus = true
                        this.startplay.src = baseurl + audiourl
                        this.startplay.play();
                    }

                }

            } else {
                this.audioindex = index
                //获取当前点击的音频地址
                if (this.startplay) {
                    this.startplay.pause();
                    this.startplay = null
                    this.playStatus = false
                    this.playStatus = true
                    // console.log(this.startplay);
                }
                this.playStatus = true
                let baseurl = this.$baseurl

                let audiourl = this.filterAudioData[this.audioindex].adultAudio
                // console.log(audiourl);
                //点击changeAudio是创建音频标签
                this.startplay = new Audio()
                this.startplay.src = baseurl + audiourl
                this.startplay.play();
                let _this = this;
                //监听音频是否布放完毕
                this.startplay.addEventListener('ended', function () {

                    //    console.log(this.ended);  
                    //改变播放状态
                    _this.playStatus = false
                    console.log('音频播放结束');
                });
            }
        },
        next(id) {
            // console.log(id);
            if (id == 3) {
                if (this.audioindex == this.filterAudioData.length - 1) {
                    console.log('结束了');
                } else {
                    this.changeAudio(this.audioindex + 1)
                }
            } else {
                if (this.audioindex == 0) {
                    console.log('到头了');
                    this.onSwipeRight1()
                } else {
                    this.changeAudio(this.audioindex - 1)
                }
            }
        },
        getID() {
            //    console.log(this.$route.query );
            listdata({id:this.pavId, version:2})
                .then((res => {
                    if (res.data.code == 200) {
                        this.prefaceData = res.data.data
                        console.log(this.prefaceData);
                        if (this.prefaceData.content) {
                            this.prefaceData.content = this.prefaceData.content.replace('<p>', '<p style="text-indent:2em">')
                        }
                    }
                }))
        },
        getaudio() {
            // console.log(this.pavId);
            quryByRId({relationId:this.pavId, version:2})
                .then((res) => {
                    if (res.data.code == 200) {
                        this.audiodata = res.data.data
                        console.log(this.audiodata);
                        for (let i = 0; i < this.audiodata.length; i++) {
                            this.filterAudioData.push(this.audiodata[i])
                        }
                        for (let j = 0; j < this.filterAudioData.length; j++) {
                            // console.log(this.filterAudioData);
                            this.filterAudioData[j].content = this.filterAudioData[j].content.replace(/<p>/g, '<p style="text-indent:2em ;">')
                        }
                        if (this.audioID) {
                            // console.log(this.filterAudioData);
                            for (let i = 0; i < this.filterAudioData.length; i++) {
                                if (this.filterAudioData[i].id == this.audioID) {
                                    this.audioindex = i
                                    this.active = i
                                    this.startplay = new Audio()
                                    this.startplay.src = this.$baseurl + this.filterAudioData[i].adultAudio
                                }

                            }
                        }
                    }

                })
        },
        //点击音频播放按钮
        StartPlaying() {
            // console.log(this.startplay);
            if (this.startplay == null) {
                this.startplay = new Audio()
                this.startplay.src = this.$baseurl + this.filterAudioData[0].adultAudio
                this.startplay.play()
            } else {
                this.startplay.play()
            }
            this.playStatus = !this.playStatus
            if (this.playStatus == false) {
                this.startplay.pause();
            }
        },
        toHome() {
            if (this.startplay) {
                this.startplay.pause();
            }

            let r = this.$route
            if (r.path == '/childrenPreface') {
                console.log('已经在首页了');
            } else {
                this.$router.push('/childrenPreface')
            }
        }
    },
    destroyed() {
        if (this.startplay) {
            this.startplay.pause();
            this.startplay = null
        }

    }


}
</script>

<style lang="scss" >
.scorychild {
    touch-action: pan-y !important;
}
.headerbox4 {
    height: 1.3rem;
    width: 100%;
    color: #fff;
    font-size: 0.44rem;
    text-align: center;
    line-height: 1.3rem;
    position: relative;

    img {
        position: absolute;
        width: 0.18rem;
        height: 0.4rem;
        top: 50%;
        transform: translateY(-50%);
        left: 0.5rem;
    }
}

.cprefacehallchild4 {
    background-image: url('../../../../assets/assets/xuesbg.png');
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .font {
        font-size: 0.6rem;
        color: #fff;
        width: 94%;
        margin: 0 auto;
        height: 1.3rem;
    }

    .content {
        width: 94%;
        margin: 0 auto;
        background: rgba(5, 83, 129, 0.8);
        border-radius: 0.5rem 0.5rem 0 0;

        .img {
            width: 100%;
            height: 3.7rem;
            // background: #ccc;
            border-radius: 0.5rem;
            box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
        }

        .brief_content {
            width: 5.5rem;
            margin: 0 auto;
            overflow: hidden;
            padding-top: 0.3rem;
            // height: calc(100% - 3.7rem);

            .brief4 {
                font-family: PingFangSC-Semibold;
                font-size: 0.34rem;
                color: #FFFFFF;
                letter-spacing: 2px;
                font-weight: 600;
        
            }

            .briefinfo {
                font-family: PingFangSC-Regular;
                font-size: 0.24rem;
                color: #FFFFFF;
                letter-spacing: 1px;
                text-align: justify;
                line-height: 0.4rem;
                font-weight: 400;
            }

            .audiobutton {
                display: flex;
                width: 100%;
                height: 1rem;
                // background: #ccc;
                margin: 0.3rem 0;
                align-items: center;
                justify-content: space-around;

            }

            .playlist {
                margin-bottom: 0.37rem;
                border-bottom: 1px solid #fff;
    

                .listitem {
                    display: flex;
                    justify-content: space-between;
                    font-size: 0.3rem;
                    color: rgb(167, 179, 203);
                    height: 0.5rem;


                }

                .playinfo {
                    font-size: 0.3rem;
                    color: #ddd;
                }
            }


        }


    }

    .activechild4 {
        color: rgb(225, 175, 73);
    }
}
</style>